@import "../variables";

.info-wrapper {
  position: absolute;
  z-index: 2;
  cursor: pointer;
}

.info-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  z-index: 1;
  padding: 20px;
  opacity: 0; // Initially hidden
  visibility: hidden; // Prevent interactions
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease; // Smooth transition
}

.info-wrapper:hover + .info-content {
  opacity: 1; // Show on hover
  visibility: visible;
}

.show-on-hover-wrapper {
  cursor: pointer;
}

.show-on-hover-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 20px;
  z-index: 100;
  padding: 20px;
  opacity: 0; // Initially hidden
  visibility: hidden; // Prevent interactions
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease; // Smooth transition
}

.show-on-hover-wrapper:hover + .show-on-hover-content {
  opacity: 1; // Show on hover
  visibility: visible;
}
