.from-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
  opacity: 0;
  transform: translateX(0); // Don't move the element off-screen
  pointer-events: none;
  clip-path: inset(0 0 0 100%); // Clip the entire element initially
  transition:
    opacity 0.3s ease,
    clip-path 0.3s ease; // Animate clipping
}

.appear {
  &.from-right {
    opacity: 1;
    clip-path: inset(0 0 0 0); // Reveal the element
    pointer-events: auto;
  }
}

.alert-wrapper {
  position: absolute;
  z-index: 2000;
  top: 32px;
  right: 0;
  width: 250px;
  opacity: 1;


  .default-alert {


  }
}
