@import "../variables";

.custom-modal-dialog {
  top: 50%; /* Center vertically */
  transform: translate(0, -50%) !important;

  &.large-screen {
    left: ($sidebar-width / 2);
  }

  &.small-screen {
    left: ($responsive-sidebar-width / 2);
  }
}

.custom-modal-content {
  border-radius: 20px !important;
  padding: 40px;
  top: 50%;

  &.secondary {
    padding: 20px 40px;
  }

  .bordered {
    border-radius: 20px;
    border: 3px solid $primary-color;
    padding: 20px;
  }
}

.custom-modal-backdrop {
  background-color: rgba(0, 0, 0) !important;
  opacity: 1 !important;
}
