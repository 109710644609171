.jakarta-sans {
  font-family: Plus Jakarta Sans;
}

.detail-section-sub-scope-name-header-wrap-up {
  /*detail-section-sub-scope-name-header-new-wrap-up*/
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 34px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 30px;
}

.detail-section-scope-name-header-wrap-up {
  /*detail-section-scope-name-header-new-wrap-up*/
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 30px;
}

.detail-sub-scope-score-header-wrap-up {
  /*.sub-scope-score-header-pdf-detail*/
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 26px;
  height: 45px;
  width: 45px;
  font-style: normal;
  font-weight: 600;
  border: 1px solid #f6c344;
  line-height: normal;
  border-radius: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.detail-main-scope-score-header-wrap-up {
  /*main-scope-score-header-pdf-detail*/
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 32px;
  height: 55px;
  width: 55px;
  font-style: normal;
  font-weight: 600;
  background: #f6c344;
  line-height: normal;
  border-radius: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.detail-scope-container-wrap-up {
  /*scope-detail-scope-container*/
  width: 100%;
  background-color: #ffffff;
  padding: 20px 7%;
  margin-bottom: 20px;
  border-radius: 20px;
}

.detail-sub-scope-container-wrap-up {
  width: 90%;
  background-color: #ffffff;
  padding: 20px 2%;
  margin-bottom: 20px;
  margin-left: 5%;
  border-radius: 20px;
}

.scale-style-wrap-up {
  /*scale-style-pdf*/
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--8, 8px);
  background: #eee;
  height: 30px;
  margin: 0 10px;
  color: #adadad;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.hit-scale-style-test-wrap-up {
  /*scale-style-pdf*/
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--8, 8px);
  height: 30px;
  margin: 0 10px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.test-scale-name-wrap-up {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #f6c344;
}

.test-scale-header-wrap-up {
  font-family: Plus Jakarta Sans;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #f6c344;
}

.hit-sub-scale-wrap-up {
  /*hit-sub-scale*/
  background: #007aff !important;
  color: #fff !important;
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  /* padding: 0 3px;
            margin: 1px 4px; */
}

.hit-scale-wrap-up {
  /*hit-scale*/
  background: #00428a !important;
  color: #fff !important;
  border-radius: 7px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 600;
  /* padding: 0 3px;
            margin: 1px 4px; */
}

.detail-scale-text-wrap-up {
  /*scale-detail-text-pdf*/
  margin-top: 10px;
  color: #222222;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  /*line-height: 20.8px;*/ /* 130% */
}

.detail-scale-general-info-text-wrap-up {
  /*scale-detail-text*/
  margin-top: 10px;
  color: #757575;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px; /* 130% */
}

.main-scope-name-wrap-up {
  /*main-scope-name-pdf*/
  color: #303030;
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.detail-scope-name-blurred-wrap-up {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  color: #c7c7c7;
}

.detail-scope-name-blurred-smaller-wrap-up {
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-weight: 600;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  padding-bottom: 10px;
  color: #c7c7c7;
}

.detail-scope-name-blurred-wrap-up-smaller {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 6.27px;
  letter-spacing: -0.01em;
  color: #c7c7c7;
}

.hit-scale-style-variable-opacity-wrap-up-round-smaller {
  font-family: Plus Jakarta Sans;
  border-radius: 50%;
  width: 46px !important;
  height: 46px !important;
  background-color: #007aff;

  color: white;
  font-size: 15px;
  font-weight: bolder;
  border: 2px solid #f6c344;
  font-style: normal;
  line-height: normal;
}

.slightly-above-absolute-child-centered-wrap-up {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -100%);
  justify-content: center;
}

._scale-detail-text-new-wrap-up {
  margin-top: 10px;
  color: #757575;
  text-align: justify;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 130% */
}

.hit-scale-style-variable-opacity-wrap-up-round {
  font-family: Plus Jakarta Sans;
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  background-color: #007aff;

  color: white;
  font-size: 20px;
  font-weight: bolder;
  border: 2px solid #f6c344;
  font-style: normal;
  line-height: normal;
}

.detail-hit-range-name-above-wrap-up {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  border-radius: 4px;
  background-color: #fff5ce;
  color: #0d5393;
}

.scale-style-variable-opacity-wrap-up-round {
  font-family: Plus Jakarta Sans;
  border-radius: 50%;
  width: 45px !important;
  height: 45px !important;
  background-color: #00428a;
  font-size: 24px;
  font-weight: 700;

  font-style: normal;
  line-height: normal;
}

.scale-style-variable-opacity-wrap-up-round-smaller {
  font-family: Plus Jakarta Sans;
  border-radius: 50%;
  width: 35px !important;
  height: 35px !important;
  background-color: #25a3ff;
  font-size: 20px;
  font-weight: 700;

  font-style: normal;
  line-height: normal;
}

.sub-scope-name-wrap-up {
  /*sub-scope-names-pdf*/
  color: #4e4e4e;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.name-style-wrap-up-thin {
  font-family: Plus Jakarta Sans;
  border-radius: var(--6, 6px);
  font-size: 20px;
  font-weight: 600;
  color: black;
  font-style: normal;
  line-height: normal;
}

.sub-name-style-wrap-up-thin {
  font-family: Plus Jakarta Sans;
  border-radius: var(--6, 6px);
  font-size: 15px;
  font-weight: 600;
  color: black;
  font-style: normal;
  line-height: normal;
}

.sub-score-style-wrap-up-thin {
  font-family: Plus Jakarta Sans;
  border-radius: var(--6, 6px);
  font-size: 12px;
  font-weight: 900;
  color: #00428a;
  font-style: normal;
  line-height: normal;
}

.score-style-wrap-up-thin {
  font-family: Plus Jakarta Sans;
  border-radius: 50%;
  width: 40px !important;
  height: 40px !important;
  background-color: #ffc900;
  font-size: 16px;
  font-weight: 700;
  color: #00428a;
  font-style: normal;
  line-height: normal;
}

.main-scope-name-detail-wrap-up-new {
  font-family: Plus Jakarta Sans;
  color: #303030;
  font-style: normal;
  height: 18px;
  margin: 0 1mm;
  font-size: 18px;
  font-weight: 1000;
  line-height: normal;
  align-content: center;
}

.main-scope-score-wrap-up {
  /*main-scope-score-pdf*/
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: #f6c344;
  float: right;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

.sub-scope-score-wrap-up {
  /*sub-scope-score-pdf*/
  color: #404040;
  text-align: center;
  font-family: Plus Jakarta Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  border: 1px solid #f6c344;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
}

.detail-section-scope-name-header-wrap-up-old {
  /*detail-section-scope-name-header-pdf*/
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding-top: 30px;
}
