@import "../variables";

.default-list {
  background-color: $white;
  border-radius: 20px !important; // Because Bootstrap "Card" overrides these
  border: none !important;
  box-shadow: none;

  &.with-shadow {
    box-shadow: 0 4px 17px 0 rgba($primary-color, 0.2),
    0 4px 17px 0 transparent;
  }

  .header {
    border: none !important;
    margin: 10px 30px;
    background-color: $secondary-color;
    border-radius: 10px !important; // Because Bootstrap "Card" overrides these
  }

  .list-container {
    border: none !important;
    margin: 10px 30px;
    background-color: $secondary-color;
    border-radius: 10px !important; // Because Bootstrap "Card" overrides these

    .list-row {
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      background-color: transparent;
      border-radius: 0 !important; // Because Bootstrap "Card" overrides these
      margin: 10px 0px;
      border-bottom: 1px solid #e7ecef;
    }
  }

  .list-row {
    border-left: none !important;
    border-right: none !important;
    border-top: none !important;
    background-color: transparent !important;
    border-radius: 0 !important; // Because Bootstrap "Card" overrides these
    margin: 10px 30px;
    border-bottom: 1px solid #e7ecef;
  }
}

.bundled-list {
  background-color: #e7ecef;

  .bundle-header {
    .bundle-child {
    }
  }
}
