.ScaleItem {
  width: 200px !important;
  max-width: 200px !important;
  min-width: 200px !important;
  margin-left: 5px;
  margin-right: 5px;
}

.ScaleItems {
  border: 1px solid;
  border-radius: 5px;
  padding: 10px;
  height: 105px;
  display: flex;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
}

.DeleteButton {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 5px;
}

.DeleteButton button {
  height: 100%;
}

.scalePreviewItem {
  height: 20px;
  /*width: 5px !important;*/
  background: lightgray;
  border-right: 1px solid;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
