@import "../variables";

@for $i from 10 through 50 {
  // text-14 makes font size 14...
  .text-#{$i} {
    font-size: #{$i}px !important;
  }
}

@each $name, $value in $colors {
  // text-gray-light makes text gray-light...
  .text-color-#{$name} {
    color: #{$value};
  }
}
