@import "../variables";

$button-height: 40px;
.content {
  position: relative;
  padding: 32px 30px 0 25px;
  overflow-y: auto; // Allow scrolling for overflowed content

  &.large-screen {
    margin-top: $header-height;
    margin-left: $sidebar-width;
    height: calc(100vh - $header-height);
    width: calc(100% - $sidebar-width);
    background-color: $secondary-color; // Replace with your variable or color
  }

  &.small-screen {
    margin-top: $header-height;
    margin-left: $responsive-sidebar-width;
    height: calc(100vh - $header-height);
    width: calc(100% - $responsive-sidebar-width);
    background-color: $secondary-color;
  }
}

.side-bar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: $white;
  color: #fff;
  z-index: 1051;

  &.large-screen {
    width: $sidebar-width;
  }

  &.small-screen {
    width: $responsive-sidebar-width;
  }

  .side-bar-non-menu-content {
    margin-left: 86px;
    margin-right: auto;

    &.small-screen {
      margin-left: auto;
    }

    &.squads {
      margin-left: 62px;
      margin-right: 62px;
      padding: 10px 24px;
    }
  }

  .side-bar-menu {
    margin-top: $header-height;

    .side-bar-company-icon {
      display: none;

      &.small-screen {
        display: flex;
        border-radius: 10px;
        width: 100%;
        padding: 20px 0;
      }
    }

    .side-bar-company-logo {
      display: flex;
      border-radius: 10px;
      width: 100%;
      padding: 20px 0;

      &.small-screen {
        display: none;
      }
    }

    .side-bar-button {
      background-color: $white;
      height: $button-height;
      font-weight: 500;
      margin-left: 40px;
      margin-right: 0;
      margin-bottom: 15px;

      &.small-screen {
        margin-left: 10px;
      }

      .side-bar-menu-icon {
        padding: 0 0 0 30px;

        &.small-screen {
          padding: 0;
        }
      }

      &:hover {
        position: relative;

        &::after {
          /* For the smooth vertical line at the end of selected menu button. */
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 3px; /* Border width */
          background: $gray-light; /* Border color */
          border-radius: 1px;
        }
      }

      &.selected {
        position: relative;
        font-weight: 600;
        background: $secondary-color;
        border-radius: 10px 0 0 10px;

        &::after {
          /* For the smooth vertical line at the end of selected menu button. */
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 3px; /* Border width */
          background: $accent-color; /* Border color */
          border-radius: 1px;
        }
      }
    }
  }
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  background-color: $primary-color;
  color: $white;
  z-index: 1052;
}
