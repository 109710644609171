@import "../variables";

.button {
  &:focus {
    background-color: transparent !important;
    border-color: transparent !important;
    outline: none !important;
    box-shadow: 0 0 1px 3px rgba($accent-color, 0.8) !important;
  }

  &.primary {
    background-color: $accent-color !important;
    border-color: $accent-color;
    border-width: 2px;
    color: $white !important;
    font-weight: 500;

    &.danger {
      background-color: $danger !important;
      border: none;

      &:hover {
        background-color: darken($danger, 10%) !important;
      }
    }

    &:hover {
      background-color: $primary-color !important;
      border-color: $primary-color;
    }

    &:disabled {
      background-color: $primary-color !important;
      border: none !important;
    }
  }

  &.secondary {
    //
    background-color: $white;
    border-color: $accent-color;
    border-width: 2px;
    color: $accent-color !important;
    font-weight: 500;

    &.danger {
      &.border-danger {
        border-color: $danger !important;
        color: $danger !important;
      }

      &:hover {
        background-color: $danger !important;
        color: $white !important;
      }
    }

    &:hover {
      background-color: $secondary-color !important;
      border-color: $primary-color;
      color: $primary-color !important;
    }

    &:disabled {
      background-color: $white !important;
    }
  }

  &.success {
    background-color: $success !important;
    border: none;

    &:hover {
      background-color: darken($success, 10%) !important;
    }
  }
}

.tab-container {
  border: 1px solid #e7ecef !important;
  border-radius: 20px;

  .tab-button {
    border: none !important;
    border-radius: 15px;
    background-color: $white;

    &.selected {
      background-color: $accent-color;
    }
  }
}

.tab-container-single-line {
  border-bottom: 1px solid #e7ecef !important;

  .tab-button {
    border-radius: 15px 15px 0 0;
    background-color: $secondary-color;
    border-bottom: 3px solid #e7ecef !important;
    transition: ease-in 0.2s;

    &.selected {
      background-color: $white;
      border-bottom: 3px solid $accent-color !important;

      color: $accent-color !important;

      box-shadow: 0px -14px 48px 6px rgba(0, 0, 0, 0.09);
    }

    &:hover {
      transition: ease-in 0.2s;
      border-bottom: 3px solid $primary-color !important;
      color: $primary-color !important;
      cursor: pointer;
    }

    &.disabled {
      &:hover {
        border-bottom: 3px solid #e7ecef !important;
        color: inherit !important;
        cursor: default;
      }
    }
  }
}
