@import "../variables";

.default-card {
  background-color: $white;
  border-radius: 20px !important; // Because Bootstrap "Card" overrides these
  border: none !important;
  box-shadow:
    0 4px 17px 0 rgba($primary-color, 0.2),
    0 4px 17px 0 transparent;

  &.no-shadow {
    box-shadow: none;
  }

  &.secondary {
    background-color: $secondary-color;
  }

  &.with-border {
    border-radius: 10px !important;
    border: 1px solid $accent-color !important;
  }

  &.unavailable {
    background-color: #f5f5f5 !important;
    box-shadow: 0 4px 17px 0 rgba($primary-color, 0.15),
    0 4px 17px 0 transparent;
    opacity: 65%;
  }

  .assessment-row {
    font-weight: 500;
    font-size: 14px;
    text-align: center;

    margin-bottom: 5px;
    padding: 8px;

    border-radius: 50px;
    border-color: #e7ecef;
    color: $gray-light;
  }
}

.breadcrumb-card {
  background-color: $white;
  border-radius: 10px !important; // Because Bootstrap "Card" overrides these
  border: none !important;
  box-shadow: none;
}
