@import "../variables";

.custom-carousel {
  padding: 0 0 30px 0;

  .carousel.slide {
    padding: 20px !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: 50%; // Center vertically
    transform: translateY(-50%);
    border-radius: 50%;
    width: 40px; // Adjust button size
    height: 40px;
    background-color: $white;
    opacity: 0.5;
    color: $primary-color;

    box-shadow:
      0 4px 10px 0 rgba($primary-color, 0.5),
      0 4px 10px 0 transparent;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .carousel-control-prev {
    left: 0px; // Adjust distance from the left
  }

  .carousel-control-next {
    right: 0px; // Adjust distance from the right
  }

  .carousel-inner {
    height: 100%;
  }

  .carousel-indicators {
    margin-bottom: -5px;

    button {
      top: 25px;
      width: 20px;
      height: 8px;
      border: none;
      border-radius: 50px;
      background-color: #e7ecef;
      cursor: pointer;

      &.active {
        background-color: $accent-color;
      }
    }
  }

  &.slide {
    padding-bottom: 0;
  }
}
