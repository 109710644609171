.show-if-under-1200 {
  display: none; // Hidden by default

  @media (max-width: 1200px) {
    display: block;
  }
}

.hide-if-under-1200 {
  @media (max-width: 1200px) {

    display: none;
  }
}
