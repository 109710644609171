td .input-group-text {
  padding: 0;
  border: none;
  background: none;
}

.table-control {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}

.table-remove-items-button {
  margin-left: 50px;
}

.table-select-all {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.table-select-all .input-group-text {
  border: none;
  background: none;
}

.table-select-all p {
  margin-bottom: 0;
}

.breadcrumb-container {
  margin-top: 50px;
}
