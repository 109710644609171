@import "../variables";

$input-border-color: #f0f0f0;
$input-background-color: $secondary-color;
$input-border-focus-color: #cfe6ff;
$input-background-focus-color: #f6fdff;

.default-text-input {
  border: 1px solid $input-border-color !important;
  border-radius: 10px !important;
  background-color: $input-background-color !important;
  outline: none;
  transition:
    border-color 0.3s ease,
    background-color 0.3s ease;

  &.white-bg {
    background-color: $white !important;

    &::placeholder {
      color: $gray-light !important;
    }

    &:focus {
      border-color: $input-border-focus-color !important;
      background-color: $white !important;
      box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25) !important;
    }
  }

  &::placeholder {
    color: $gray-light !important;
  }

  &:focus {
    border-color: $input-border-focus-color !important;
    background-color: $input-background-focus-color !important;
    box-shadow: 0 0 0 1px rgba(13, 110, 253, 0.25) !important;
  }

  &:disabled {
    background-color: #f5f5f5;
    border-color: #ddd;
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    border-color: darken($input-border-color, 10%);
  }

  // Invalid State Styles
  &.is-invalid,
  &:invalid {
    border-color: #dc3545 !important; // Bootstrap Danger Color (or custom)
    background-color: lighten(#dc3545, 40%) !important; // Light red background
    box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25) !important;

    &::placeholder {
      color: lighten(#dc3545, 20%) !important; // Slightly lighter red
    }
  }

  // Additional Focused Invalid State
  &.is-invalid:focus,
  &:invalid:focus {
    border-color: darken(#dc3545, 10%) !important; // Darker red border
    box-shadow: 0 0 0 2px rgba(220, 53, 69, 0.5) !important;
  }
}
