@import "../variables";

.hover-bg-darken {
  cursor: pointer;
  border-radius: 10px;
  background-color: transparent;
  transition: 0.1s ease;

  &:hover {
    border-radius: 10px;
    background-color: $secondary-color !important;
  }
}

.hover-bg-lighten {
  cursor: pointer;
  border-radius: 10px;
  background-color: transparent;
  transition: 0.1s ease;

  &:hover {
    border-radius: 10px;
    background-color: $white !important;
  }
}

.hover-shadow {
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 17px 0 rgba($primary-color, 0.6),
    0 8px 17px 0 transparent;
  }
}

.hover-container {
  position: relative;

  // Target the hover-actions element no matter its position in the DOM
  &:hover {
    // Use a global ancestor selector
    .hover-actions {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto; // Enable interaction when visible
    }

    .hover-actions-reversed {
      opacity: 0;
      transform: translateY(-10px); // Slightly offset
      pointer-events: none; // Prevent interaction when hidden
    }
  }
}

// Hover actions are styled globally, regardless of their location
.hover-actions {
  opacity: 0; // Initially hidden
  transform: translateY(-10px); // Slightly offset
  pointer-events: none; // Prevent interaction when hidden
  transition: opacity 0.3s ease,
  transform 0.3s ease; // Smooth animation
}

.hover-actions-reversed {
  opacity: 1; // Initially shown
  transform: translateY(0);
  pointer-events: auto; // Enable interaction when visible
  transition: opacity 0.3s ease,
  transform 0.3s ease; // Smooth animation
}
