@import "../variables";
// Import the SCSS variables

.montserrat {
  font-family: $font-family, sans-serif;
}

.page-background {
  background-color: $secondary-color;
}





.absolute-top-right {
  position: absolute;
  right: 5%;
  top: 5%;

  &.corner {
    right: 0;
    top: 0;
  }
}

.absolute-bottom-right {
  position: absolute;
  right: 5%;
  bottom: 5%;

  &.corner {
    right: 0;
    bottom: 0;
  }
}

.absolute-top-left {
  position: absolute;
  left: 5%;
  top: 5%;

  &.corner {
    left: 0;
    top: 0;
  }
}

.absolute-bottom-left {
  position: absolute;
  left: 5%;
  bottom: 5%;

  &.corner {
    left: 0;
    bottom: 0;
  }
}
