.pdf-header-footer-text {
  color: #4e4e4e;
  height: 18px;
  margin: 0 1mm;
  font-size: 10px;
  font-weight: 800;
  line-height: normal;
  align-content: center;
}

.pdf-copyright-text {
  color: #4e4e4e;
  height: 18px;
  margin: 0 1mm;
  font-size: 8px;
  font-weight: 800;
  line-height: normal;
  align-content: center;
}
