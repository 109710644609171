/* Primary Color Palette (60%) */
$primary-color: #274c77; /* DARK BLUE (HEADER) */
$primary-light: #244c77;
$primary-dark: #274c77;

/* Secondary Color Palette (30%) */
$secondary-color: #f8f9fa; /* GREY (PAGE BACKGROUND) */
$secondary-light: #f8f9fa;
$secondary-dark: #f8f9fa;

/* Accent Colors (10%) */
$accent-color: #0077b6; /* LIGHT BLUE (ACCENTS) */
$accent-light: #0077b6;
$accent-dark: #0077b6;

/* Neutral Colors */
$white: #ffffff;
$black: #000000;
$gray-light: #8b8c89;
$gray-dark: #303030;
$danger: #dc3545;
$page-bg: #f8f9fa;
$success: #18b26b;

$colors: (
  primary: $primary-color,
  secondary: $secondary-color,
  accent: $accent-color,
  white: $white,
  black: $black,
  gray-light: $gray-light,
  gray-dark: $gray-dark,
        danger: $danger,
        page-bg: $page-bg,
        success: $success,
);

@each $name, $value in $colors {
  // bg-color-gray-light makes background color gray-light...
  .bg-color-#{$name} {
    background-color: #{$value};
  }
}

/* Typography */
$font-family: "Montserrat"; /* jakarta sans */
$header-height: 95px;
$sidebar-width: 345px;
$responsive-sidebar-width: 60px;
